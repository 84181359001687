<script>
import { aboutList } from "@/api/my";
export default {
    components: {
        Header: () => import('components/base/Header'),

        'mt-header': app.Mint.Header,
        'mt-button': app.Mint.Button,
        'mt-cell': app.Mint.Cell,
    },
    directives: {
        'infinite-scroll': app.Mint.InfiniteScroll,
    },
    data() {
        return {
            list: [],
            header: {
                mainTitle3: getWord('about_us2')
            }
        };
    },
    methods: {
        left_click() {
            let hisNum = parseInt(window.history.length);
            if (hisNum == 1) {
                this.$router.push("/home");
            } else {
                this.$router.go(-1);
            }
        },
        getDetail(i) {
            this.$router.push({ path: "/my/about/listDetail?id=" + i });
        },

        loadMore() {
            if (this.list.length < this.length) {
                this.loading = true;
                setTimeout(() => {
                    let last = this.list[this.list.length - 1];
                    for (let i = 1; i <= 1; i++) {
                        this.list.push(last + i);
                    }
                    this.loading = false;
                }, 50);
            }
        },

        getmes() {
            aboutList("2").then(result => {
                if (result.data.code == "SUCCESS") {
                    this.list = result.data.result.list;
                    this.CustomScroll(this.$refs.main.id);
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            });
        }
    },
    mounted() {
        this.getmes();
    }
};
</script>
<template>
    <div class="about" id="about" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
            <Header :mainTitle="header.mainTitle3" :backButton=false />
            <div class="myposition"></div>
            <div class="main" id="main" ref="main">
                <!-- <router-link to="/my/about/listDetail"> -->
                <div v-for="(item,i) in list" :key="i" v-infinite-scroll="loadMore" infinite-scroll-disabled="loading" infinite-scroll-distance="10">
                    <mt-cell :title="item.title" class="mt_cell">
                        <img style="width:0.22rem;height:0.39rem;" src="@@/assets/images2/user/about/yjt.png" alt @click="getDetail(list[i].id)">
                    </mt-cell>
                </div>
                <!-- </router-link> -->
            </div>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header :mainTitle="header.mainTitle3" :backButton=false />
            <div class="myposition"></div>
            <div class="main" id="main" ref="main">
                <!-- <router-link to="/my/about/listDetail"> -->
                <div v-for="(item,i) in list" :key="i" v-infinite-scroll="loadMore" infinite-scroll-disabled="loading" infinite-scroll-distance="10">
                    <mt-cell :title="item.title" class="mt_cell">
                        <img style="width:0.22rem;height:0.39rem;" src="@@/assets/images2/user/about/yjt.png" alt @click="getDetail(list[i].id)">
                    </mt-cell>
                </div>
                <!-- </router-link> -->
            </div>
        </template>
    </div>
</template>
<style scoped lang='scss'>
#about {
    display: flex;
    flex-direction: column;
    height: 100%;

    .main {
        flex-grow: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
}

.myposition {
    background-color: rgba(239, 239, 239, 1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.mt_cell {
    border-bottom: 2px solid #ccc;
    width: 100%;
}
</style>